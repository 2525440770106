import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import '../assets/sass/main.scss';

class SimpleLayout extends Component {
    render() {
        const { children } = this.props;
        return (
            <>
                <Helmet
                    title="Login Ya"
                    meta={[
                        { name: 'description', content: 'Login Ya' },
                    ]}
                >
                    <html lang="en" />
                </Helmet>
                {children}
            </>
        );
    }
}

SimpleLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default SimpleLayout;
