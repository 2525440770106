import { navigate } from 'gatsby'
import React from 'react'

import Login from '../components/Login'
import SimpleLayout from '../components/SimpleLayout'
import { isLoggedIn } from '../services/auth'


const LoginPage = () => {
    if (isLoggedIn()) {
        navigate("/")
        return null
    }

    return (
        <SimpleLayout>
            <Login />
        </SimpleLayout>
    )
};

export default LoginPage;